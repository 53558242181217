import './App.css';
import heartIcon from './icons/heart-icon.svg'

function App() {
  return (
    <div>
      <body>
        <div className="center font-main">
            <div className="heart-image" style={{backgroundImage: `url(${heartIcon})`}}>
              <br/>
              <br/>
              
              <p>hey Kisu,
              <br/>
              will you be my valentine?
              <br/>
              love,
              <br/> 
              Rahul Pateddy
              </p>
            </div>
        </div>
      </body>
    </div>
  );
}

export default App;
